








import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {},
})
export default class CrmHome extends Vue {
  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  dialog = true;

  get message(): string {
    const isProspect = this.clientType === ClientTypeEnum.Prospect;
    return this.$t(`crm.view.home.${isProspect ? 'selectProspectToShowInfo' : 'selectClientToShowInfo'}`).toString();
  }
}
